export function formatCurrency(value) {
    let v = value;

    if (Number.isFinite(v) === false) {
        v = 0.0;
    }

    const options = {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    };

    return new Intl.NumberFormat("en-US", options).format(v);
}
