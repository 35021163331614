<script>
	import { formatCurrency } from "../../configurator/common/format.js";

	export let cost;
	export let installation;
</script>

<div class="estimated">
	<h4 class="type-30-light m-type-24-light">Estimated cost</h4>
	<h4 class="type-30-light m-type-24-light">{formatCurrency(cost)}</h4>
	<p class="type-14 color-text-40">
		{formatCurrency(cost - installation)} current configuration<br />
		+ {formatCurrency(installation)} base installation cost
	</p>
</div>

<style>
	.estimated {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 36px 0;
		border-top: rgba(0, 0, 0, 0.1) 1px solid;
		padding-top: 32px;
	}

	.estimated h4 {
		margin: 0;
		padding: 0;
		font-family: regola-book, sans-serif;
	}

	.estimated p {
		margin-top: 8px;
		color: rgba(0, 0, 0, 0.4);
		line-height: 133% !important;
	}
</style>
