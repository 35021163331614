<script>
	import { onMount } from "svelte";
	import calculator from "../stores/calculator.js";
	import Slider from "../../financing/views/Slider.svelte";
	import FormFieldInput from "../../common/FormFieldInput.svelte";

	export let cost;

	let localCost = cost;
	let downPercent = $calculator.down;
	let term = $calculator.term;
	let rate = ($calculator.rate * 100.0).toFixed(1);

	function toNumber(value, parser) {
		return (
			parser(
				`${value}`.replace("$", "").replace(",", "").replace("%", "")
			) || 0.0
		);
	}

	function formatValues() {
		downPercent = Math.ceil($calculator.down * 100).toFixed();
		term = toNumber($calculator.term, parseInt).toFixed();
	}

	function didChangeValue() {
		formatValues();

		gtag("event", "calculator_value_changed");
	}

	function didChangeDownPercent(e) {
		downPercent = e.detail.value / localCost;

		calculator.updateValues({
			down: downPercent
		});
	}

	function didChangeTerm(e) {
		term = e.detail.value;
		calculator.updateValues({ term: toNumber(term, parseInt) });
	}

	function didChangeRate() {
		calculator.updateValues({ rate: toNumber(rate, parseFloat) / 100.0 });
	}

	$: isFieldInvalid = (name) => {
		return $calculator.invalidValues.includes(name);
	};

	onMount(formatValues);
</script>

<form>
	<div class="form-row">
		<div class="field" class:invalid={isFieldInvalid("term")}>
			<label for="calculator-term">Term</label>
			<div class="input">
				<FormFieldInput
					variant="financing"
					type="select"
					name="termYears"
					options={[
						[30, "30 years"],
						[20, "20 years"],
						[15, "15 years"]
					]}
					on:change={didChangeTerm}
					on:change={didChangeValue} />
			</div>
		</div>

		<div class="field" class:invalid={isFieldInvalid("rate")}>
			<label for="calculator-rate">Interest rate</label>
			<div class="input">
				<div class="overlay type-18 m-type-15">
					<span>{rate}</span>%
				</div>
				<input
					class="type-18 m-type-15 calculator-rate-input"
					id="calculator-rate"
					name="rate"
					type="text"
					inputmode="decimal"
					bind:value={rate}
					on:input={didChangeRate}
					on:input={didChangeValue} />
			</div>
		</div>
	</div>

	<div class="spacer-8"></div>

	<div class="form-row">
		<div class="field merge">
			<label for="calculator-down">Down payment</label>
			<Slider
				min={0}
				relativeMin={0}
				max={localCost}
				relativeMax={1}
				value={downPercent * localCost}
				roundingFactor={1000}
				shouldDisplayRelativeLimitIndicators={true}
				shouldExpandRangeAutomatically={false}
				minIndicator="0%"
				maxIndicator="100%"
				on:change={didChangeDownPercent} />
		</div>
	</div>
</form>

<style>
	.invalid,
	.invalid input,
	.invalid label {
		opacity: 1;
		color: rgb(187, 38, 44) !important;
	}

	.invalid .overlay {
		display: none;
	}

	.form-row {
		display: flex;
		gap: 12px;
		margin-top: 16px;
	}

	.field {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		width: 100%;
	}

	.field.merge {
		display: block;
	}

	label {
		opacity: 0.4;
		margin-bottom: 8px;
		width: 100%;
		font-size: 14px;
		line-height: 18px;
	}

	.input {
		position: relative;
		box-sizing: border-box;
		width: 100%;
	}

	@media (max-width: 768px) {
		.input {
			border-radius: 8px;
			border-radius: 10px;
		}
	}

	input:focus {
		outline: var(--color-blue) 2px solid;
	}

	.invalid input:focus {
		outline: rgba(187, 38, 44, 1) 2px solid;
		box-shadow: 0 0 0 4px rgba(187, 38, 44, 0.25);
	}

	.overlay {
		position: absolute;
		top: 1px;
		left: 0.5px;
		opacity: 0.5;
		padding: 17px 20px 18px 20px;
		pointer-events: none;
	}

	@media (max-width: 768px) {
		.overlay {
			top: 1.5px;
			left: 1px;
			padding: 16px 18px;
		}
	}

	.overlay span {
		opacity: 0;
	}

	input {
		appearance: none;
		box-sizing: border-box;
		outline: none;
		border: none;
		background: transparent;
		padding: 16px 20px 18px 20px;
		width: 100%;
		font-size: 15px;
	}

	@media (max-width: 768px) {
		input {
			padding: 10px 12px 11px 12px;
			font-size: 16px;
		}
	}

	.calculator-rate-input {
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-radius: 12px;
		background: var(--color-black-2);
		padding: 0 18px;
		padding-top: 17px;
		padding-bottom: 19px;
	}
</style>
