import { optionWithKey } from "../configurator/common/options";
import Calculator from "./Calculator.svelte";
import calculator from "./stores/calculator.js";

function createCalculator(variant, cost, installation, layoutIndex = 0) {
    const target = document.querySelector("#calculator");

    const model = optionWithKey("layout").values[layoutIndex].key;

    const instance = new Calculator({
        target,
        props: {
            variant,
            cost,
            installation,
            model
        }
    });

    return function destroyCalculator() {
        target.classList.add("dismiss");
        instance.$destroy();
        target.classList.remove("dismiss");
    };
}

let destroy;

window.Calculator = {
    show(variant, cost, installation, layoutIndex) {
        if (destroy !== undefined) {
            return;
        }

        if (cost === undefined) {
            calculator.reset();
        }

        destroy = createCalculator(variant, cost, installation, layoutIndex);

        switch (variant) {
            case "ice":
                gtag("event", "all_in_cost_opened");
                break;

            case "calculator-imageless":
                gtag("event", "calculator_opened");
                break;
        }
    },

    hide() {
        if (destroy === undefined) {
            return;
        }

        destroy();
        destroy = undefined;
    }
};
