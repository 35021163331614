<script>
	import InstallationCostExplained from "../common/InstallationCostExplained.svelte";
	import Header from "./views/Header.svelte";
	import EstimatedCost from "./views/EstimatedCost.svelte";
	import Form from "./views/Form.svelte";
	import Result from "./views/Result.svelte";
	import calculator from "./stores/calculator.js";
	import Modal from "../common/Modal.svelte";
	import { costForConfiguration } from "../configurator/stores/cost.js";

	export let variant;
	export let cost = undefined;
	export let installation = undefined;
	export let model = "studio";

	if (cost === undefined) {
		const calculatedCost = costForConfiguration({ layout: model });
		cost = calculatedCost.total;
		installation = calculatedCost.installation;
	}

	function didDismissModal() {
		window.Calculator.hide();
	}

	if (variant === "both") {
		calculator.reset();
	}
</script>

<Modal on:dismiss={didDismissModal}>
	<div class="calculator grid" data-variant={variant}>
		<div class="row">
			{#if variant === "both" || variant === "ice"}
				<div class="column six info">
					<InstallationCostExplained />
				</div>
			{/if}
			{#if variant === "both" || variant.startsWith("calculator")}
				<div class="column six form">
					<Header />
					{#if variant === "calculator-imageless"}
						<EstimatedCost {cost} {installation} />
					{/if}
					<Form {cost} />
					<div class="spacer-72 m-spacer-64"></div>
					<Result {model} {cost} />
				</div>
			{/if}
		</div>
	</div>
</Modal>

<style>
	.calculator {
		display: flex;
		position: relative;
		will-change: transform;
		margin: auto;
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
		border-radius: 24px;
		background: #ffffff;
		max-width: 540px;
		font-family: regola-book, sans-serif;
	}

	@media (max-width: 768px) {
		.calculator {
			display: block;
			margin: 0;
		}
	}

	.column {
		box-sizing: border-box;
		margin: 0;
		border-radius: 24px;
		background: #ffffff;
		padding: 50px 60px;
		max-width: 720px;
	}

	@media (max-width: 768px) {
		.column {
			border-radius: 18px;
			padding: 32px;
		}
	}

	.calculator[data-variant="both"] .info {
		border-radius: 0;
		border-top-left-radius: 24px;
		border-bottom-left-radius: 24px;
		background: #fafafa;
	}

	@media (max-width: 768px) {
		.calculator[data-variant="both"] .info {
			border-top-right-radius: 24px;
			border-top-left-radius: 24px;
			border-bottom-left-radius: 0;
		}
	}

	.calculator[data-variant="both"] .form {
		border-radius: 0;
		border-top-right-radius: 24px;
		border-bottom-right-radius: 24px;
	}

	@media (max-width: 768px) {
		.calculator[data-variant="both"] .form {
			border-top-right-radius: 0;
			border-bottom-left-radius: 24px;
		}
	}

	@keyframes reveal {
		0% {
			transform: translateY(50px);
			opacity: 0;
		}

		100% {
			transform: none;
			opacity: 1;
		}
	}

	@keyframes reveal-background {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}
</style>
