<script>
	function didClickLearnMoreLink(e) {
		if (document.body.classList.contains("hello") === true) {
			return;
		}

		e.preventDefault();
		e.stopPropagation();

		window.dispatchEvent(new CustomEvent("modal:dismiss"));
		setTimeout(openRoute, 300, "/backyard/financing");
	}
</script>

<div class="header">
	<h1 class="type-36 m-type-30-light">Calculate payments.</h1>
	<div class="spacer-16"></div>
	<p class="type-15 m-type-14 color-text-60">
		Need financing? No problem. Financing for Backyard is a second mortgage
		with lower rates and more flexible terms than your typical first
		mortgage.
	</p>
	<div class="spacer-16"></div>
	<p class="type-15 m-type-14 color-text-60">
		<a
			on:click={didClickLearnMoreLink}
			href="https://www.samara.com/backyard/financing">
			Learn more about Financing for Backyard
		</a>
	</p>
</div>

<style>
	.header {
		display: flex;
		flex-direction: column;
	}

	p {
		margin: 0;
		padding: 0;
	}
</style>
